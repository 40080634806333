import React from 'react';
import { Container } from './styles';
import GridScaper from '../GridScaper';

import logo from '../../../assets/logo-horizontal.svg';

const Footer: React.FC = () => {
  return (
    <Container><GridScaper>
      <img src={logo} alt="logo" />
    </GridScaper>
    </Container>
  );
};

export default Footer;
