import React, { useState } from 'react';
import { Container, ContainerBadge, Logo, Menu, MenuItem, MenuToggle } from './styles';
import { deleteData } from '../../../services/localStorage';
// import { useRouter } from 'next/navigation';
import { FaBars } from 'react-icons/fa';
import GridScaper from '../GridScaper';

import logo from '../../../assets/logo-horizontal.svg';

const Badge: React.FC = () => {
  // const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  // const toggleOnBlurMenu = () => {
  //   console.log('onBlur')
  // };

  const handleLogout = () => {
    deleteData('token');
    // router.push('/');
  };

  return (
    <ContainerBadge>
      <MenuToggle onClick={toggleMenu}>
        <FaBars />
      </MenuToggle>
      {menuOpen && (
        <Menu>
          <ul>
            <MenuItem>Item 1</MenuItem>
            <MenuItem>Item 2</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </ul>
        </Menu>
      )}
    </ContainerBadge>
  );
}

const Header: React.FC = () => {
  return (
    <Container><GridScaper>
      <Logo>Logo</Logo>
      <img src={logo} alt="logo" />
      <Badge />
    </GridScaper>
    </Container>
  );
};

export default Header;
