function saveData(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

function getData(key: string) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

function updateData(key: string, newData: any) {
  const existingData = getData(key);
  if (existingData) {
    const updatedData = { ...existingData, ...newData };
    saveData(key, updatedData);
  }
}

function deleteData(key: string) {
  localStorage.removeItem(key);
}


export { saveData, getData, updateData, deleteData }